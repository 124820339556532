import { Box } from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { RoutePattern } from "../../../routes/RoutePattern";
import PAPER from "../../../assets/images/paper.jpg";
import ALBUM_ART from "../../../assets/images/Halfway There.png";
import LOGO_IMG from "../../../assets/images/halfway there logo.png";
import DESKTOP_BG from "../../../assets/images/Halfway There desktop.png";
import MOBILE_BG from "../../../assets/images/Halfway There mobile.png";
import DESKTOPHALF_BG from "../../../assets/images/Halfway There half desktop.png";
import styled from "styled-components";
import { data } from "./data";
// import useTwitterService from "../../Hooks/useTwitterService";
// import { isMobile } from "../../../constants";

const LeftContainer = styled.div`
  background-image: url('${DESKTOP_BG}');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  &.half-bg {
    background-image: url('${DESKTOPHALF_BG}');
  }

  @media (max-width: 767px) {
    background-image: url('${MOBILE_BG}');
  }
`;

const RightContainer = styled.div`
`;

const Button = styled.button`
  background-color: rgb(0 0 0);
  color: #FFF;
  border-color: #FFF;
`;

const ImageContainer = styled.div`
  height: 30vh;
  /* width: 10vw;
  background-image: url('${ALBUM_ART}'); */
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 0.65rem;
`;

const MobileFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
`;

const Link = styled.a`
  background-image: url(${PAPER});
  cursor: default;
`;

const Showcase = () => {
  const [tweetCount, setTweetCount] = useState<number>(0);
  const ms = Date.now();

  fetch('./tweet/count.txt?check='+ms)
  .then((response) => response.json())
  .then((data)=> {
    setTweetCount(data)
  });

  // console.log("Tweet Count:",tweetCount);

  const trackItems = data.map((item, idx) =>
    tweetCount >= 500 ? (
      <a
        key={idx}
        href={item.url} // route
        className="flex flex-col border h-[50px] m-auto text-base justify-center items-center leading-5 uppercase py-2 mb-1 hover:bg-custom-bg hover:text-black"
        style={{borderColor: "#676BFF"}}
        target="_blank"
      >
        {idx+1}. {item.title}
        <div
          className="text-xs"
        >
          {item.subTitle}
        </div>
      </a>
    ) : (
      <Link
        key={idx}
        className="flex flex-col h-[50px] m-auto text-base justify-center items-center leading-5 uppercase py-2 mb-1"
      ></Link>
    )
  );

  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-row justify-center items-center h-screen w-full"
    >
      <LeftContainer
        className={`fixed left-0 h-full ${
          tweetCount >= 500 ? "w-1/2 hidden half-bg" : "w-full -mt-40 md:-mt-0 -mb-24 md:mb-0 pb-24 md:pb-0 flex"
         } md:flex flex-col justify-center items-center bg-white text-black`}
      >
        <ImageContainer
          className="border-black border mb-5 mt-0 md:-mt-10 hidden"
        >
          <img src={ALBUM_ART} className="h-full" />
        </ImageContainer>
        <div
          className="uppercase text-3xl md:text-4xl font-bold font-sans text-white text-shadow hidden"
        >
          03 Greedo
        </div>
        <div
          className="uppercase text-3xl md:text-4xl font-bold font-sans mb-4 md:mb-8 text-white text-shadow hidden"
        >
          Halfway There | 3.24
        </div>
        <div
          className={`text-lg md:text-2xl font-semibold text-center text-white text-shadow w-[95vw] ${
            tweetCount < 500 ? "md:w-1/3" : "md:w-4/5"
           } mb-3 mt-80 bg-black/60 p-2`}
        >
          <div className="pb-2 md:pb-4">Join the hashtag #HalfwayThere to reveal the tracklist.</div>
          <div>Drop your Cash App in Your Tweet for a Chance to Win a Cash Prize from 03 Greedo.</div>
        </div>
        <a
          className={`w-[95vw] ${
            tweetCount < 500 ? "md:w-2/5 3xl:w-2/6" : "md:w-4/5 3xl:w-4/6"
           }`}
          href="https://twitter.com/intent/tweet?text=%4003greedo%20%23HalfwayThere%20Dropping%20at%20Midnight.%20Tracklist%20loading%20...&url=https://letterstogreedo.com"
          rel="noreferrer"
          target="_blank"
        >
          <Button
            className="w-full text-sm py-3 border-black border-x-4 border-y font-bold uppercase"
          >
            @03greedo #HalfwayThere Dropping at Midnight. Tracklist loading ... https://www.letterstogreedo.com/
          </Button>
        </a>
        <a
          className={`w-[95vw] ${
            tweetCount < 500 ? "md:w-1/4" : "md:w-2/5"
           } hidden`}
          href="#"
          rel="noreferrer"
          target="_blank"
        >
          <Button
            className="w-full text-sm leading-10 border-black border-x-4 border-b font-bold uppercase"
          >
            pre-save Halfway There
          </Button>
        </a>
        <Footer
          className="mb-10 md:mb-5 hidden"
        >
          <a
            href="#"
            className="mx-2"
            target="_blank"
          >
            PRIVACY POLICY
          </a>
          <a
            href="#"
            className="mx-2"
            target="_blank"
          >
            TERMS AND CONDITION
          </a>
        </Footer>
      </LeftContainer>
      <RightContainer
        className={`absolute right-0 h-screen ${
          tweetCount < 500 ? "hidden" : ""
         } w-full md:w-1/2 h-screen pb-20 md:pb-0`}
        style={{color: "#676BFF"}}
      >
        <div
          className="text-center w-[150px] h-[150px] mx-auto mt-8 mb-16"
          style={{borderColor: "#676BFF"}}
        >
          <img src={LOGO_IMG} className="h-full" />
        </div>
        <div className="w-3/4 md:w-2/6 m-auto">
          {trackItems}
        </div>
        <div
          className="text-center border w-[100px] h-[50px] mx-auto mt-16 mb-20 md:mb-8 invisible"
          style={{borderColor: "#676BFF"}}
        >
          Footer
        </div>
        <div className="pb-24 md:pb-2"></div>
        <div id="canvasContainer" className="fixed h-screen w-full -z-30 top-0"></div>
      </RightContainer>
      <MobileFooter
        className={`w-full flex flex-row ${
          tweetCount < 500 ? "hidden" : ""
         } md:hidden`}
      >
        {/* <a
          href="#"
          target="_blank"
          className="flex-1 bg-white text-black text-center leading-10 uppercase font-semibold py-1 border-black border border-y-0 border-r-1"
        >
          Pre-save
        </a> */}
        <a
          href="https://twitter.com/intent/tweet?text=%4003greedo%20%23HalfwayThere%20Dropping%20at%20Midnight.%20Tracklist%20loading%20...&url=https://letterstogreedo.com"
          target="_blank"
          className="flex-1 bg-white text-black text-center uppercase font-semibold py-2"
        >
          @03greedo #HalfwayThere Dropping at Midnight. Tracklist loading ... https://www.letterstogreedo.com/
        </a>
      </MobileFooter>
    </Box>
  );
};

export default Showcase;
