export const data = [
  {
    title: "Bacc Like I Never Left",
    url: "https://twitter.com/intent/tweet?text=Track%201%20of%20Halfway%20There%20is%20titled%20Bacc%20Like%20I%20Never%20Left.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Big Gang",
    url: "https://twitter.com/intent/tweet?text=Track%202%20of%20Halfway%20There%20is%20titled%20Big%20Gang.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Made Parole",
    url: "https://twitter.com/intent/tweet?text=Track%203%20of%20Halfway%20There%20is%20titled%20Made%20Parole.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Hummers",
    url: "https://twitter.com/intent/tweet?text=Track%204%20of%20Halfway%20There%20is%20titled%20Hummers.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Industry",
    url: "https://twitter.com/intent/tweet?text=Track%205%20of%20Halfway%20There%20is%20titled%20Industry.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Buss Me A Script",
    subTitle: "feat. Maxo Kream",
    url: "https://twitter.com/intent/tweet?text=Track%206%20of%20Halfway%20There%20is%20titled%20Buss%20Me%20A%20Script%20feat.%20Maxo%20Kream.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Dada",
    subTitle: "feat. Babyface Ray",
    url: "https://twitter.com/intent/tweet?text=Track%207%20of%20Halfway%20There%20is%20titled%20Dada%20feat.%20Babyface%20Ray.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Forever Millionaires",
    subTitle: "feat. Rich The Kid",
    url: "https://twitter.com/intent/tweet?text=Track%208%20of%20Halfway%20There%20is%20titled%20Forever%20Millionaires%20feat.%20Rich%20The%20Kid.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "How Could I",
    url: "https://twitter.com/intent/tweet?text=Track%209%20of%20Halfway%20There%20is%20titled%20How%20Could%20I.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Glass",
    url: "https://twitter.com/intent/tweet?text=Track%2010%20of%20Halfway%20There%20is%20titled%20Glass.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Coi Leray Diamonds",
    subTitle: "feat. Ty Dolla $ign",
    url: "https://twitter.com/intent/tweet?text=Track%2011%20of%20Halfway%20There%20is%20titled%20Coi%20Leray%20Diamonds%20feat.%20Ty%20Dolla%20%24ign.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Aint Gone Slide",
    subTitle: "feat. Skilla Baby",
    url: "https://twitter.com/intent/tweet?text=Track%2012%20of%20Halfway%20There%20is%20titled%20Aint%20Gone%20Slide%20feat.%20Skilla%20Baby.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Paisa",
    subTitle: "feat. Peso Peso",
    url: "https://twitter.com/intent/tweet?text=Track%2013%20of%20Halfway%20There%20is%20titled%20Paisa%20feat.%20Peso%20Peso.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Freaks",
    url: "https://twitter.com/intent/tweet?text=Track%2014%20of%20Halfway%20There%20is%20titled%20Freaks.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Picc It Up",
    url: "https://twitter.com/intent/tweet?text=Track%2015%20of%20Halfway%20There%20is%20titled%20Picc%20It%20Up.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Steady Poppin",
    url: "https://twitter.com/intent/tweet?text=Track%2016%20of%20Halfway%20There%20is%20titled%20Steady%20Poppin.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Day Shift",
    url: "https://twitter.com/intent/tweet?text=Track%2017%20of%20Halfway%20There%20is%20titled%20Day%20Shift.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "2 Percs",
    url: "https://twitter.com/intent/tweet?text=Track%2018%20of%20Halfway%20There%20is%20titled%202%20Percs.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "1 Drink 2 Many",
    url: "https://twitter.com/intent/tweet?text=Track%2019%20of%20Halfway%20There%20is%20titled%201%20Drink%202%20Many.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Spend Time Pt 2",
    url: "https://twitter.com/intent/tweet?text=Track%2020%20of%20Halfway%20There%20is%20titled%20Spend%20Time%20Pt%202.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Like I'm Regular",
    url: "https://twitter.com/intent/tweet?text=Track%2021%20of%20Halfway%20There%20is%20titled%20Like%20I%27m%20Regular.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Link Back Up",
    url: "https://twitter.com/intent/tweet?text=Track%2022%20of%20Halfway%20There%20is%20titled%20Link%20Back%20Up.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "I Don't Wanna Fight",
    url: "https://twitter.com/intent/tweet?text=Track%2023%20of%20Halfway%20There%20is%20titled%20I%20Don%27t%20Wanna%20Fight.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Directions",
    subTitle: "feat. Peewee Longway",
    url: "https://twitter.com/intent/tweet?text=Track%2024%20of%20Halfway%20There%20is%20titled%20Directions%20feat.%20Peewee%20Longway.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Untouchable",
    subTitle: "feat. Whokid Woody",
    url: "https://twitter.com/intent/tweet?text=Track%2025%20of%20Halfway%20There%20is%20titled%20Untouchable%20feat.%20Whokid%20Woody.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Load The 9",
    subTitle: "feat. Peewee Longway",
    url: "https://twitter.com/intent/tweet?text=Track%2026%20of%20Halfway%20There%20is%20titled%20Load%20The%209%20feat.%20Peewee%20Longway.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Nightmares",
    url: "https://twitter.com/intent/tweet?text=Track%2027%20of%20Halfway%20There%20is%20titled%20Nightmares.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Demons Pt 2",
    url: "https://twitter.com/intent/tweet?text=Track%2028%20of%20Halfway%20There%20is%20titled%20Demons%20Pt%202.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Since You Been Gone",
    url: "https://twitter.com/intent/tweet?text=Track%2029%20of%20Halfway%20There%20is%20titled%20Since%20You%20Been%20Gone.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "6pm-6am",
    url: "https://twitter.com/intent/tweet?text=Track%2030%20of%20Halfway%20There%20is%20titled%206pm-6am.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Rockstar Lifestyle",
    url: "https://twitter.com/intent/tweet?text=Track%2031%20of%20Halfway%20There%20is%20titled%20Rockstar%20Lifestyle.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Run The Money Up",
    url: "https://twitter.com/intent/tweet?text=Track%2032%20of%20Halfway%20There%20is%20titled%20Run%20The%20Money%20Up.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
  {
    title: "Come Inside",
    url: "https://twitter.com/intent/tweet?text=Track%2033%20of%20Halfway%20There%20is%20titled%20Come%20Inside.&url=https://www.letterstogreedo.com&hashtags=HalfwayThere&via=03Greedo",
  },
];
