import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import { useEffect, useRef, useState } from "react";

export default function Home() {
  const [tweetCount, setTweetCount] = useState<number>(0);
  const ms = Date.now();

  fetch('./tweet/count.txt?check='+ms)
  .then((response) => response.json())
  .then((data)=> {
    setTweetCount(data)
  });

  const bgColor = tweetCount >= 500 ? "transparent" : "rgb(0 0 0)";
  
  return (
    <Box className="text-white h-screen w-full flex flex-col justify-center items-center" style={{backgroundColor: bgColor }}>
      <Showcase />
    </Box>
  );
}
